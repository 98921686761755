import React from 'react'
import Props from 'prop-types'

const CopyIcon = ({ width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path d="M28 51.906c13.055 0 23.906-10.851 23.906-23.906 0-13.078-10.875-23.906-23.93-23.906C14.899 4.094 4.095 14.922 4.095 28c0 13.055 10.828 23.906 23.906 23.906Zm10.406-34.804c2.906 2.882 2.649 6.445-.703 9.773l-4.031 4.055c.328-1.125.305-2.438-.07-3.328l2.367-2.368c2.297-2.25 2.554-4.617.726-6.422-1.804-1.78-4.172-1.5-6.422.75l-3.351 3.305c-2.32 2.344-2.625 4.735-.797 6.516.539.562 1.336.914 2.367 1.101-.352.75-1.078 1.618-1.758 2.086-.703-.117-1.594-.68-2.344-1.453-2.906-2.883-2.601-6.492.797-9.914l3.422-3.398c3.352-3.352 6.914-3.586 9.797-.703ZM16.75 38.758c-2.906-2.883-2.649-6.446.727-9.774l4.03-4.054c-.35 1.125-.327 2.437.048 3.328l-2.368 2.367c-2.297 2.227-2.554 4.617-.726 6.422 1.805 1.781 4.195 1.5 6.422-.75l3.351-3.305c2.32-2.344 2.625-4.734.797-6.515-.539-.563-1.336-.915-2.367-1.102.351-.75 1.078-1.617 1.758-2.086.703.117 1.593.68 2.367 1.453 2.883 2.883 2.578 6.469-.82 9.89l-3.422 3.423c-3.352 3.351-6.914 3.586-9.797.703Z" />
  </svg>
)

CopyIcon.defaultProps = {
  width: 24,
  height: 24,
}

CopyIcon.propTypes = {
  height: Props.number,
  width: Props.number,
}

export default CopyIcon
