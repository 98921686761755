import styled from 'styled-components'
import { theme } from 'common/styles'
import ReactMarkdown from 'react-markdown'

export const Container = styled.section`
  display: grid;
  grid-template-areas: 'image download' 'article download';
  position: relative;
  justify-content: center;
  width: 100%;

  ${theme.mq.mediumDown.css`
        grid-template-areas: 'image' 'article' 'download';
    `}
`

export const CoverImage = styled.img`
  grid-area: image;
  height: 600px;
  width: 1080px;
  max-width: 100%;
  object-fit: cover;
`

export const Article = styled.article`
  grid-area: article;
  padding: 4rem 0 0;
  max-width: 680px;
  margin: 0 auto;
  margin-bottom: 4rem;
`

export const ArticleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const AuthorBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`

export const Author = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 600;
  gap: 0.3rem;
`

export const AuthorJob = styled.span`
  color: ${theme.colors.grey100};
  font-weight: 400;
`

export const PublishedDate = styled.div`
  font-size: 1.6rem;
  color: ${theme.colors.grey100};
`

export const Avatar = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    width: 100%;
  }
`

export const Markdown = styled(ReactMarkdown)`
  img {
    max-width: 100%;
  }

  a {
    color: ${theme.colors.primary};
  }

  p {
    line-height: 1.6;
    margin: 0;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.6rem;
  }

  h4 {
    font-size: 2.3rem;
  }

  h5 {
    font-size: 2rem;
  }

  h6 {
    font-size: 1.8rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }
`

export const Description = styled.p`
  font-size: 2.5rem;
  color: ${theme.colors.grey100};
`

export const Sharing = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
  align-items: center;

  svg {
    width: 3rem;
    height: 3rem;
    transition: color 0.2s;

    &:hover {
      color: ${theme.colors.darkGrey};
    }
  }
`

export const CopyIconLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transform: translateY(-2px);

  svg {
    width: 3.4rem;
    height: 3.4rem;
  }
`

export const CopiedText = styled.span`
  font-size: 1.4rem;
  position: absolute;
  bottom: -2rem;
`

export const Download = styled.div`
  grid-area: download;
  padding: 2rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 30rem;
  max-height: 100vh;
  border-left: 1px solid ${theme.colors.lightGrey};

  ${theme.mq.mediumDown.css`
        border-left: none;
    `}
`

export const DownloadLabel = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

export const DownloadDescription = styled.div`
  font-size: 1.5rem;
  line-height: 1.4;
  color: ${theme.colors.grey100};
  margin-bottom: 2rem;
`

export const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${theme.colors.primary};
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${theme.colors.darkGrey};
  }
`
