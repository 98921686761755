import React from 'react'
import Props from 'prop-types'
import {
  Container,
  Article,
  CoverImage,
  Download,
  Description,
  Markdown,
  Sharing,
  CopiedText,
  CopyIconLi,
  PublishedDate,
  ArticleInfo,
  AuthorBadge,
  Avatar,
  Author,
  AuthorJob,
  DownloadLabel,
  DownloadDescription,
  DownloadLink,
} from './styled'
import dayjs from 'dayjs'
import IconFacebook from 'components/Icons/IconFacebook'
import IconTwitter from 'components/Icons/IconTwitter'
import CopyIcon from 'components/Icons/CopyIcon'
import copy from 'copy-to-clipboard'

const EXTERNAL_URL_REGEX = /^(?:[a-z]+:\/{2}|[a-z]+:|www\.).+$/iu

const BlogPost = ({ post }) => {
  const contentRef = React.useRef(null)
  const [copied, setCopied] = React.useState(false)

  const handleCopy = React.useCallback(() => {
    copy(window.location.href)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [])

  React.useEffect(() => {
    if (!contentRef.current) {
      return
    }

    // Add target="_blank" to all external links
    const links = contentRef.current.querySelectorAll('.markdown-content a')
    links.forEach(link => {
      // Check with regex
      if (EXTERNAL_URL_REGEX.test(link.href)) {
        link.target = '_blank'
        link.rel = 'noopener noreferrer nofollow'
      }
    })
  }, [])

  return (
    <Container>
      <CoverImage src={post.coverImage} alt={post.title} />
      <Article ref={contentRef}>
        <ArticleInfo>
          <AuthorBadge>
            {post.authorImage && (
              <Avatar>
                <img src={post.authorImage} alt={post.authorName} />
              </Avatar>
            )}
            <Author>
              <span>{post.authorName}</span>
              <AuthorJob>{post.authorJob}</AuthorJob>
            </Author>
          </AuthorBadge>
          <PublishedDate>
            {dayjs(post.date, 'YYYY-MM-DD').format('MMMM D, YYYY')}
          </PublishedDate>
        </ArticleInfo>
        <h1>{post.title}</h1>
        <Description>{post.description}</Description>
        <Markdown className="markdown-content">{post.content}</Markdown>
        <Sharing>
          <CopyIconLi>
            <CopyIcon onClick={handleCopy} />
            {copied && <CopiedText>Copied</CopiedText>}
          </CopyIconLi>
          <li>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=https://zoeapp.co/blog/${post.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconFacebook />
            </a>
          </li>
          <li>
            <a
              href={`https://twitter.com/share?text=${post.title}&url=https://zoeapp.co/blog/${post.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter />
            </a>
          </li>
        </Sharing>
      </Article>
      <Download>
        <DownloadLabel>Download Zoe for free</DownloadLabel>
        <DownloadDescription>
          Connect with 7M+ lesbian, bisexual, and queer women around the world
        </DownloadDescription>
        <img src="/media/zoe-qr.png" width="100%" alt="Download Zoe for free" />
        <DownloadLink
          rel="noopener noreferrer nofollow"
          href="https://zoe.sng.link/Ao858/pfiyz/54r7"
        >
          Download Free
        </DownloadLink>
      </Download>
    </Container>
  )
}

BlogPost.defaultProps = {
  post: {},
}

BlogPost.propTypes = {
  post: Props.object,
}

export default BlogPost
