import React from 'react'
import Props from 'prop-types'

import Page from 'components/Page'
import Navigation from 'components/Navigation'
import Container from 'components/Styled/Container'
import { PageContent } from 'components/Styled/Layout'

import BlogPost from 'components/BlogPost'
import { Helmet } from 'react-helmet'

const Blog = ({ pageContext }) => (
  <Page title={pageContext.post.title} translates={pageContext.lang}>
    <Helmet>
      <meta name="description" content={pageContext.post.description} />
      <meta name="og:title" content={pageContext.post.title} />
      <meta name="og:description" content={pageContext.post.description} />
      <meta name="og:image" content={pageContext.post.coverImage} />
      <meta name="og:url" content={pageContext.post.url} />
      <meta name="og:type" content="article" />
      <meta name="author" content={pageContext.post.authorName} />
      <meta name="article:published_time" content={pageContext.post.date} />
    </Helmet>
    <Navigation isMobileVisible translates={pageContext.lang} />
    <Container
      style={{
        maxWidth: '1300px',
      }}
    >
      <PageContent>
        <BlogPost post={pageContext.post} />
      </PageContent>
    </Container>
  </Page>
)

Blog.propTypes = {
  location: Props.shape({
    search: Props.string,
  }).isRequired,
  pageContext: Props.any.isRequired,
}

export default Blog
